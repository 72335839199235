.customPicker {
  text-align: center;
  font-size: 20px;
  background-color: transparent;
  color: "#1E1E1E";
  width: 100%;
}

.noScrollBar::-webkit-scrollbar {
  width: 0;
}

/* .filepond--root,
.filepond--root .filepond--drop-label {
  height: 1px;
  max-height: 1px;
} */

/* date range picker */
.datePicker {
  border-radius: 10px;
}

.chakra-ui-dark .datePicker {
  /* background-color: var(--chakra-colors-gray-600); */
  background-color: #1E1E1E;
}

.chakra-ui-dark .rdrNextPrevButton {
  /* background-color: var(--chakra-colors-gray-600); */
  background-color: #1E1E1E;
}

.chakra-ui-dark .datePicker .rdrDayPassive .rdrDayNumber span {
  color: var(--chakra-colors-gray-400);
}

.chakra-ui-dark .datePicker .rdrDayDisabled {
  background-color: var(--chakra-colors-gray-500);
}

.chakra-ui-dark .datePicker .rdrDayDisabled .rdrDayNumber span {
  color: var(--chakra-colors-gray-300);
}

.chakra-ui-dark .datePicker .rdrDayNumber span {
  color: white;
}

.chakra-ui-dark .datePicker .rdrWeekDay {
  color: white
}

.chakra-ui-dark .datePicker .rdrMonthPicker select {
  color: white;
  appearance: auto;
}

.chakra-ui-dark .datePicker .rdrYearPicker select {
  color: white;
  appearance: auto;
}

.chakra-ui-dark .datePicker .rdrYearPicker select option {
  color: #1E1E1E;
}

.chakra-ui-dark .datePicker .rdrMonthPicker select option {
  color: #1E1E1E;
}

/* date time picker */
.customDateTime .rdtPicker {
  min-width: 270px;
  border-radius: 10px;
  box-shadow: var(--chakra-shadows-2xl);
  top: 35px;
  left: -10px;
  font-weight: 400;
}

.customDateTime .rdtPicker .dow {
  font-weight: 400;
}

.customDateTime input:hover {
  background: inherit;
}

.customDateTime input:focus-visible {
  outline: none;
}

.customDateTime input {
  background: inherit;
  text-align: center;
}

.customDateTime .rdtSwitch:hover {
  cursor: pointer;
  background-color: #eee;
}

.chakra-ui-dark .rdtPicker {
  background: #222;
  border: 1px solid #333;
}

.chakra-ui-dark .customDateTime .rdtPicker {
  background: var(--chakra-colors-gray-600) !important;
  border-color: var(--chakra-colors-gray-300) !important;
}

.chakra-ui-dark .customDateTime .rdtDay:hover,
.chakra-ui-dark .customDateTime .rdtTimeToggle:hover,
.chakra-ui-dark .customDateTime .rdtSwitch:hover,
.chakra-ui-dark .customDateTime .rdtBtn:hover,
.chakra-ui-dark .customDateTime .rdtNext:hover,
.chakra-ui-dark .customDateTime .rdtPrev:hover,
.chakra-ui-dark .customDateTime .rdtMonth:hover,
.chakra-ui-dark .customDateTime .rdtYear:hover {
  background-color: var(--chakra-colors-gray-700);
}

.reactGiphySearchbox-searchForm-input {
  padding-left: 10px !important;
  border-radius: 7px;
}

.chakra-ui-dark .reactGiphySearchbox-searchForm-input {
  background: var(--chakra-colors-gray-800);
  border: 1px solid rgb(74, 85, 104);
}

.tooltip-mention {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip-mention .tooltip-mention-text {
  visibility: hidden;
  width: 250px;
  background-color: white;
  /* border: 1px solid lightgray; */
  box-shadow: rgba(100, 100, 111, 0.20) 0px 2px 19px 0px;
  color: #000;
  text-align: left;
  padding: 12px;
  border-radius: 6px;
  top: 40px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-mention:hover .tooltip-mention-text {
  visibility: visible;
}

.chakra-collapse {
  overflow: initial !important;
  position: relative;
  z-index: 1
}

highlight-input {
  background-color: var(--chakra-colors-twitter-50) !important;
  color: var(--chakra-colors-twitter-700) !important;
  border-radius: 4px !important;
  padding: 4px !important;
  cursor: pointer;
}

.chakra-ui-dark highlight-input {
  background-color: var(--chakra-colors-gray-700) !important;
  color: var(--chakra-colors-twitter-400) !important;
}

.custom-editor-styles {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: inherit !important;
  font-style: inherit !important;
  min-height: 65px;
  max-height: 300px;
  padding: 5px;
}

.custom-editor-styles:focus {
  box-shadow: none !important;
  border: none;
}

.remirror-editor .ProseMirror-selectednode {
  outline: 2px solid #0A65C1 !important;
}

.with-tooltip {
  display: inline-block;
  color: rgb(10, 101, 193);
  font-weight: var(--chakra-fontWeights-bold);
}

.highlighted {
  background: #7963d233;
}

.suggestion {
  padding: 2px 8px;
}

.suggestions {
  border: 1px solid darkgray;
  border-radius: 4px;
}

.pulse-glow {
  animation: pulse-glow 1s ease-in-out infinite alternate;
}

@keyframes pulse-glow {
  from {
    filter: drop-shadow(0 0 0px #fff);
  }

  to {
    filter: drop-shadow(0 0 8.91px #fff) drop-shadow(0 0 17.81px #fff) drop-shadow(0 0 62.34px #fff) drop-shadow(0 0 124.68px #fff) drop-shadow(0 0 213.73px #fff) drop-shadow(0 0 374.03px #fff);
  }
}

.edit-sent-msg {
  height: auto;
  padding: 0;
}

.chakra-popover__popper {
  z-index: 101 !important;
}

.upload-image-filepond.filepond--root,
.upload-image-filepond.filepond--root .filepond--drop-label {
  min-height: 120px !important;
}

.filepond--icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.filepond--panel-dark .filepond--drop-label {
  background-color: #1E1E1E;
  border-radius: 5px;
  color: #F2F2F2;
  display: flex;
  min-height: 120px !important;
  flex-direction: column;
  padding: 1rem;
}

.upload-image-filepond.filepond--panel-dark .filepond--drop-label {
  border: 1px solid #3b3b3b;
}

.filepond--panel-light .filepond--drop-label {
  background-color: #ECECEC;
  border-radius: 5px;
  min-height: 120px !important;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.filepond--drop-label label {
  display: flex !important;
  flex-direction: column;
  align-items: center;
}

.filepond--panel-dark .filepond--drip {
  opacity: 1 !important;
  background-color: #1E1E1E;
}

.filepond--panel-light .filepond--icon {
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;
}

.filepond--panel-dark .filepond--icon {
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;
}